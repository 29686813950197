import request from "../utils/request.js";

function os(){
    let u = navigator.userAgent;
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
        return 1;
    }
    if (isIOS) {
        return 2;
    }
    return 10;
}

export const $stat = (type) => {
    return request({
        url:'/stat/cli',
        method:'post',
        data:{
            type,
            platform:os(),
            fromk: window.localStorage.getItem('fromk')
        }
    })
}

export const $statWeChatCli = (params) => {//统计上报接口
    return request({
        url:'/stat/weChatCli',
        method:'post',
        data:params
    })
}
