import { render, staticRenderFns } from "./Paipan.vue?vue&type=template&id=ca3f150c&scoped=true&"
import script from "./Paipan.vue?vue&type=script&lang=js&"
export * from "./Paipan.vue?vue&type=script&lang=js&"
import style0 from "./Paipan.vue?vue&type=style&index=0&id=ca3f150c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca3f150c",
  null
  
)

export default component.exports