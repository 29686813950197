<template>
    <div class="Result_" id="agentTitle">
        <header >
            <div class="label_box">
                <span class="label_text label_text_left">我的虎年运势</span>
                <span class="label_text label_text_right" @click="nameMatchingSkip">
                    <i class="hot_img_box">
                        <img src="https://cdn.zhiyileiju.cn/WechatIMG637.pic" alt="">
                    </i>
                    <i class="fire_img_box">
                        <img src="https://cdn.zhiyileiju.cn/WechatIMG636.pic" alt="">
                    </i>
                    婚姻测试
                </span>
            </div>
        </header>
        <section>
            <div class="banner_img_box_"><img src="https://cdn.zhiyileiju.cn/WechatIMG1318.jpeg" alt=""></div>

            <div class="info_color_box_"><i class="icon_img_"><img src="https://cdn.zhiyileiju.cn/WechatIMG1300.pic" alt=""></i> 恭喜你！成功领取你的专属虎年运势报告。</div>
            <!-- <div class="info_text_box_">请仔细查收哈，测试报告公众号领取后可反复查阅哦~</div> -->
            <!-- <div class="Official_Accounts_buttom_"><i class="Official_Accounts_icon_"><img src="" alt=""></i> 点击快速进入公众号</div> -->

            <div class="content_box_">
                <h1 class="title_">您的基本信息</h1>
                <div class="content_name_text_box_">
                    <p class="name_ span_style__1"><span class="text__">姓</span>名：{{info_data.name}}</p>
                    <p class="xex_ span_style__1"><span class="text__">性</span>别：{{info_data.sex}}</p>
                    <p class="date_of_birth_"><span class="text__">出生日期</span>：</p>
                    <p class="Gregorian_calendar_ span_style__1"><span class="text__">公</span>历：{{`${lunar_calendar_value.cYear}年${lunar_calendar_value.cMonth}月${lunar_calendar_value.cDay}日${lunar_calendar_value.tiem_}时`}}</p>
                    <p class="lunar_calendar_ span_style__1"><span class="text__">阴</span>历：{{`(${lunar_calendar_value.yearCn_})${lunar_calendar_value.yearCn}${lunar_calendar_value.monthCn}${lunar_calendar_value.dayCn}${lunar_calendar_value.tiemCn_}`}}</p>
                </div>
            </div>
            <div class="content_box_">
                <h1 class="title_">八字五行分析</h1>
                <div class="horoscope_box">
                        <div class="eight_layout">
                            
                            <Paipan
                                :sex="orderinfo.sex"
                                :data="orderinfo.eightChara"
                                style="width:calc(100% - 30px);padding-left:15px"
                            />
                            <h2 class="text">五行分析喜用字</h2>
                            <!-- <div class="tit1 ml-15" style="font-size: .32rem;
                            text-align:center;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #E7010E;">
                                五行生克关系
                            </div> -->
                            <div class="txt ml-15">
                                金{{ orderinfo.askTao.ori_qk_score["金"] }} 木{{
                                orderinfo.askTao.ori_qk_score["木"]
                                }}
                                水{{ orderinfo.askTao.ori_qk_score["水"] }} 火{{
                                orderinfo.askTao.ori_qk_score["火"]
                                }}
                                土{{ orderinfo.askTao.ori_qk_score["土"] }}
                            </div>
                            <div class="txt ml-15">
                                个人运势不是按照五行缺什么就补什么，<span class="color-red"
                                >应以先天八字中五行同类和异类互为平衡原则，补充最需要的五行作为喜用。</span
                                >
                            </div>
                            <div class="txt">
                                您的日主天干属<span class="color-red">{{
                                orderinfo.askTao.benming
                                }}</span
                                >，经计算，您的八字同类五行是
                                <span
                                v-for="(value, key, index) in orderinfo.askTao.ww_score_ratio"
                                :key="key"
                                class="color-red"
                                >{{ key }}<span v-if="index == 0">、</span></span
                                >
                                。
                            </div>
                            <div class="center">
                                <img
                                style="width:70%"
                                src="//cdn.names.org.cn/website/Content/szname/img/WxGif2.gif"
                                />
                            </div>
                            <!-- <WuxingCate
                                :data="orderinfo.askTao"
                                :qk_score_ratio="orderinfo.askTao.qk_score_ratio"
                                style="padding:0 15px;margin:15px 0"
                            /> -->
                            <div class="tit1 ml-15" style="
                            text-align:center;
                            font-size: .32rem;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #E7010E;
                            ">
                                您的五行数量
                            </div>
                            <div class="center wchl">
                                <!-- 五行含量组件 -->
                                <WuxingHl
                                :score="orderinfo.askTao.qk_score"
                                :qk_score_ratio="orderinfo.askTao.qk_score_ratio"
                                />
                            </div>
                            <div class="tit1 ml-15">
                                五行喜用分析
                            </div>
                            <div class="txt ml-15">
                                同类含量：{{ WW[0] }} + {{ WW[1] }}＝{{ WWrd }}
                            </div>
                            <div class="txt ml-15">
                                异类含量：{{ DW[0] }} ＋ {{ DW[1] }} ＋{{ DW[2] }}＝{{ DWrd }}
                            </div>
                            <div class="txt ml-15">综合旺弱判定</div>
                            <div class="txt ml-15">
                                同类含量/异类含量＝{{ (WWrd / DWrd).toFixed(2) }}
                            </div>
                            <div class="txt ml-15">经准确分析，该八字的喜用如下：</div>
                            <div class="tit1 ml-15">喜用五行</div>
                            <div class="txt ml-15 xjwx">
                                喜用五行:
                                <em
                                :class="enums[key]"
                                v-for="(value, key) in orderinfo.askTao.asktao_result['喜用']"
                                :key="key"
                                >{{ key }}</em
                                >
                            </div>
                            <div class="txt ml-15 xjwx">
                                忌用五行：
                                <em
                                :class="enums[key]"
                                v-for="(value, key) in orderinfo.askTao.asktao_result['忌用']"
                                :key="key"
                                >{{ key }}</em
                                >
                            </div>
                            <div class="txt ml-15">
                                根据上述分析过程，已详细为您推算出您的五行喜用，后天通过补足、增旺五行，成就一生幸福。
                            </div>
                            <div class="txt ml-15">
                                综合该八字五行喜用分析和五行平衡，找另一半或者找朋友时，选择五行
                                <span
                                v-for="(value, key, index) in orderinfo.askTao.asktao_result['喜用']"
                                :key="index"
                                class="color-red"
                                >【{{ key }}】</span
                                >
                                旺的，有利于感情稳定，幸福长久。
                            </div>
                            <div class="txt ml-15" v-html="test_info.bz_ori">
                                
                            </div>
                            <h1 style="
                                text-align:center;
                                font-size: .32rem;
                                font-family: Microsoft YaHei;
                                font-weight: bold;
                                color: #E7010E;
                            ">您的八字性格</h1>
                            <div class="txt ml-15" v-html="test_info.xg">
                                
                            </div>
                        </div>
                </div>
            </div>
            <div class="content_box_">
                <h1 class="title_">2022年流年运势</h1>
                <div class="content_text_box_">
                    <p v-html="test_info.lucky_info_year"></p>
                </div>
            </div>
            <div class="content_box_">
                <h1 class="title_">八字运势详解</h1>
                <div class="content_text_box_">
                    <p style="font-weight:bold;padding-bottom:0;text-align:center;color: #E7010E;font-size: .32rem;">婚姻运势</p>
                    <p v-html="test_info.bz_marriage"></p>

                    <p style="font-weight:bold;padding-bottom:0;">命中桃花运：</p>
                    <p v-html="test_info.py_status.zheng"></p>
                    <p style="font-weight:bold;padding-bottom:0;">命中烂桃花：</p>
                    <p v-html="test_info.py_status.fan"></p>

                    <p style="font-weight:bold;padding-bottom:0;text-align:center;color: #E7010E;font-size: .32rem;">事业运势</p>
                    <p v-html="test_info.bz_business"></p>
                    <p style="font-weight:bold;padding-bottom:0;text-align:center;color: #E7010E;font-size: .32rem;">财运运势</p>
                    <p v-html="test_info.bz_money"></p>
                </div>
            </div>
            <div class="content_box_">
                <h1 class="title_">2022年流月运势</h1>
                <div class="content_text_box_">
                    <p style="font-weight:bold;padding-bottom:0;text-align:center;color: #E7010E;font-size: .32rem;">寅月运势</p>
                    <p v-html="test_info.lucky_info_month[0]"></p>
                    <p style="font-weight:bold;padding-bottom:0;text-align:center;color: #E7010E;font-size: .32rem;">卯月运势</p>
                    <p v-html="test_info.lucky_info_month[1]"></p>
                    <p style="font-weight:bold;padding-bottom:0;text-align:center;color: #E7010E;font-size: .32rem;">辰月运势</p>
                    <p v-html="test_info.lucky_info_month[2]"></p>
                    <p style="font-weight:bold;padding-bottom:0;text-align:center;color: #E7010E;font-size: .32rem;">巳月运势</p>
                    <p v-html="test_info.lucky_info_month[3]"></p>
                    <p style="font-weight:bold;padding-bottom:0;text-align:center;color: #E7010E;font-size: .32rem;">午月运势</p>
                    <p v-html="test_info.lucky_info_month[4]"></p>
                    <p style="font-weight:bold;padding-bottom:0;text-align:center;color: #E7010E;font-size: .32rem;">末月运势</p>
                    <p v-html="test_info.lucky_info_month[5]"></p>
                    <p style="font-weight:bold;padding-bottom:0;text-align:center;color: #E7010E;font-size: .32rem;">申月运势</p>
                    <p v-html="test_info.lucky_info_month[6]"></p>
                    <p style="font-weight:bold;padding-bottom:0;text-align:center;color: #E7010E;font-size: .32rem;">酉月运势</p>
                    <p v-html="test_info.lucky_info_month[7]"></p>
                    <p style="font-weight:bold;padding-bottom:0;text-align:center;color: #E7010E;font-size: .32rem;">戌月运势</p>
                    <p v-html="test_info.lucky_info_month[8]"></p>
                    <p style="font-weight:bold;padding-bottom:0;text-align:center;color: #E7010E;font-size: .32rem;">亥月运势</p>
                    <p v-html="test_info.lucky_info_month[9]"></p>
                    <p style="font-weight:bold;padding-bottom:0;text-align:center;color: #E7010E;font-size: .32rem;">子月运势</p>
                    <p v-html="test_info.lucky_info_month[10]"></p>
                    <p style="font-weight:bold;padding-bottom:0;text-align:center;color: #E7010E;font-size: .32rem;">丑月运势</p>
                    <p v-html="test_info.lucky_info_month[11]"></p>
                    <!-- <p style="color:#67386F;">
                        温馨提示：您的先天婚姻是根据您的出生时辰，结合易经原理，仔细分析的结果，描述的是您先天命理中婚姻感情的状况。可供您在做婚恋选择时参考，找到真正爱您的另一半。
                    </p> -->
                </div>
            </div>
            <div class="content_box_">
                <h1 class="title_">犯太岁及化解太岁</h1>
                <div class="content_text_box_">
                    <p v-html="test_info.jupiter"></p>
                </div>
                <div class="content_text_box_">
                    <p style="font-weight:bold;padding-bottom:0;text-align:center;color: #E7010E;font-size: .32rem;">化解太岁办法</p>
                    <p style="font-weight:bold;padding-bottom:0;color: rgba(82, 82, 82, 1);font-size: .28rem;">1、请化太岁符：</p>
                    <p >若是遇上犯太岁的年份，请化太岁符是最有效的办法之一。具体的做法是选择宫观，结合自身的生辰八字请来太岁符，平时好随身携带，直到本命年结束再通过仪式焚烧送走。佩戴太岁符可以为自身挡灾，破除霉运，提升运势。</p>
                    <p style="font-weight:bold;padding-bottom:0;color: rgba(82, 82, 82, 1);font-size: .28rem;">2、躲太岁：</p>
                    <p >本命年大家要注意躲太岁，关于躲太岁的方式也很
                        多。按照农历的计算方式，太岁是在新一年的第一
                        个节气“立春”时刻到来，在立春这一天，人们要
                        注意“躲春”即在太岁交接的时刻，避免大声喧哗
                        或者外出走动，最好躲在家里避免见生人。2022年
                        立春的具体时间是2月4日星期五下午4点50分36秒，
                        因此在这个时间点里要注意躲春。
                        </p>
                    <p style="font-weight:bold;padding-bottom:0;color: rgba(82, 82, 82, 1);font-size: .28rem;">3、佩戴吉祥物：</p>
                    <p >犯太岁的年份，人们可以通过佩戴吉祥物的方式来
                        提升自己的运势。比如，每个人可以选择自己的本
                        命佛佩戴，或者佩戴与自己生肖属相相合的挂件。
                        另外，各类水晶、幸运宝石等也有不同的功效，大
                        家可以根据自身的情况来选择。
                    </p>


                    <p style="font-weight:bold;padding-bottom:0;text-align:center;color: #E7010E;font-size: .32rem;">虎年化解太岁办法</p>
                    <p>如果觉得以上方法太过于繁琐，或者当地没有可以
                    请太岁符的宫观，大家也可以通过其他一些简单的
                    方式来化解。下面就来介绍一下2022虎年化解太岁
                    最简单的方式有哪些。</p>
                    <p style="font-weight:bold;padding-bottom:0;color: rgba(82, 82, 82, 1);font-size: .28rem;">1、穿红衣：</p>
                    <p >
                        本命年穿红衣已经成为了传统，在遇到本命年的时
                        候，人们大多会购买红色的如果觉得以上方法太过
                        于繁琐，或者当地没有可以请太岁符的宫观，大家
                        也可以通过其他一些简单的方式来化解。
                        下面就来介绍一下2022虎年化解太岁最简单的方式
                        有哪些。
                        内衣裤或者红裤头、红袜子等来穿戴，作为犯太岁
                        的吉祥饰物，寓意新年好运加身，红红火火，好运
                        常伴。
                    </p>
                    <p style="font-weight:bold;padding-bottom:0;color: rgba(82, 82, 82, 1);font-size: .28rem;">2、多做好事：</p>
                    <p >本命年里，人们可以通过积德行善的方式来化解太
                        岁带来的不利因素。平时在生活中如果遇到身边的
                        朋友或者同事有难处，若是自己的能力也做的到，
                        那么可以尽量多帮助别人。有些人还会在犯太岁的
                        年份里选择放生，收养流浪动物或做慈善捐款等，
                        这也是做好事的一种方式。
                        </p>
                    <p style="font-weight:bold;padding-bottom:0;color: rgba(82, 82, 82, 1);font-size: .28rem;">3、避免参加丧葬礼仪：</p>
                    <p >犯太岁的年份，大家要避开参加丧葬场合，因为这
                        些地方的氛围本就比较消沉，犯太岁者自身的气场
                        较弱，如果毫不避讳参加可能会带来霉运，诸事不
                        顺。另外，到医院探望病人也是本命年较为忌讳的
                        事情，因此这方面要多注意。
                    </p>
                    <p style="font-weight:bold;padding-bottom:0;color: rgba(82, 82, 82, 1);font-size: .28rem;">4、避免口舌是非：</p>
                    <p >人与人交往的过程中难免会出现一些是非困扰，但
                        犯太岁的年份要尤其注意，避免与人发生口舌是非。
                        这一年里大家要保持低调，哪怕遇到他人挑衅，也
                        要多注意忍让，如果为了逞一时的口舌之快而与对
                        方发生不愉快，那么很有可能造成更加严重的后果。
                    </p>
                    <p style="font-weight:bold;padding-bottom:0;color: rgba(82, 82, 82, 1);font-size: .28rem;">5、避免投资转化：</p>
                    <p >虎年亦要提防官非诉讼，签署文件、合约前应请专
                        业人士帮忙把关，以免导致对簿公堂。另外，驿马
                        强的年份走动较为频繁，容易有无辜破财机会，短
                        炒投机可免则免。不宜轻举妄动作重大决定，投资
                        方面更需格外谨慎，以免有决策错误情况。既然新
                        一年运势有较多掣肘，建议将目标略为调低，不宜
                        对事业抱有太大期望，轻松渡过反而表现更为理想。
                    </p>
                    <p style="font-weight:bold;padding-bottom:0;color: rgba(82, 82, 82, 1);font-size: .28rem;">6、注意避免远行：</p>
                    <p >尽量避免有远行的行动，因为在虎年，你的出行运
                        势不是很好，所以你一定要管好自己的腿。当然还
                        有就是跟工作有关的事情，有可能会有出差的事情
                        发生，你们可以推脱掉或者找个借口，如果实在不
                        行那么请一定要注意防范。
                    </p>
                    <p >遇到休假的时候，建议与家人一块待在家中享受天
                        伦之乐，不要长途旅行或者乘坐飞机、火车等交通
                        工具，把一切可能威胁到健康安全的因素都考虑到
                        ，开车人士出门在外要特别注意出行安全，切记遵
                        守交通规则，不可疲劳驾驶，更不可酒后驾驶。
                    </p>
                </div>
            </div>
            <div class="content_box_">
                <h1 class="title_">2022年增运建议</h1>
                <div class="content_text_box_">
                    <p style="font-weight:bold;padding-bottom:0;text-align:center;color: #E7010E;font-size: .32rem;">运程把握建议</p>
                    <p style="font-weight:bold;padding-bottom:0;color: rgba(82, 82, 82, 1);font-size: .32rem;">今年的吉星凶星情况</p>
                    <p>吉星：太阳、天乙、天空 <br>
                        凶星：咸池桃花、晦气、的煞<br>
                        概述：贵人助力充足，吉星力量大利拓展海外市场；<br>
                        桃花多姿多彩，惟已婚者要提防三角关系。
                    </p>
                    <p style="font-weight:bold;padding-bottom:0;color: rgba(82, 82, 82, 1);font-size: .28rem;">大利动中生财</p>
                    <p>流年的吉星力量强大兼光照远地，如果能够多走动
                    开拓海外市场，事业发展将会更加如虎添翼。建议
                    虎年应主动争取出差机会，以「动中生财」方式带
                    动运势；亦可贴身佩戴绿幽灵及葡萄石手串，招来
                    贵人及提升业绩，有利加强正财运。</p>
                    <p style="font-weight:bold;padding-bottom:0;color: rgba(82, 82, 82, 1);font-size: .28rem;">催旺桃花人缘</p>
                    <p>十二生肖当中桃花最为灿烂，单身一族感情畅旺，
                    尤其有利女性，不妨透过友人介绍物色另一半，有
                    望结识条件出众的对象；另外亦可于流年一白桃花
                    星（正北）飞临位置摆放粉晶许愿球，有助催旺人
                    缘及提升个人魅力。</p>
                    <p style="font-weight:bold;padding-bottom:0;color: rgba(82, 82, 82, 1);font-size: .28rem;">驱赶小人是非</p>
                    <p>人际关系出现倒退现象，尤其与同事相处时需要谨
                    言慎行，亦可于家居或办公室的三碧是非星（正东）
                    飞临方位或工作桌面摆放鞋形纸镇，有利招揽贵人
                    临门，驱赶小人及化解口舌是非，减少人事纷扰。</p>
                    <p style="font-weight:bold;padding-bottom:0;color: rgba(82, 82, 82, 1);font-size: .28rem;">提早预防冲击</p>
                    <p>因虎年桃花畅旺、应酬频繁，容易因为生活欠缺规
                    律而身体不适，日常宜多加注意作息。此外，宜提
                    早于虎年第四季进行身体检查，亦可佩戴盐源玛瑙
                    手串，增强个人磁场及整体运势。</p>
                    <p style="font-weight:bold;padding-bottom:0;text-align:center;color: #E7010E;font-size: .32rem;">流年不利提醒</p>
                    <p>流年不利实际上就是时运不佳。八字中流年是指，
                    我们所经历的每一年，由于每一年的五行，对不同
                    人的八字产生生克制化的关系不同，就有了流年的
                    吉凶。这一年如何就是看这一年的五行天干地支，
                    跟你命局的天干地支形成的生克结果。</p>
                    <p>
                    <span style="font-weight:bold;padding-bottom:0;color: rgba(82, 82, 82, 1);font-size: .28rem;">逢流年不利，需要注意：<br></span>
                    1、低调谨慎、小心行事 <br>
                    “吉凶生互动”，意思就是当知道好事情要发生就
                    尽量去努力争取，很容易事半功倍；提前知道坏事
                    要发生最好是不要有太大动作，最好少动的意思。
                    小心驶得万年船，流年不利的时候做事做人要低调
                    谨慎，切莫张扬高调，以防得罪小人，惹上官司缠
                    身。不要做违法之事，流年运势除了跟流年有关外
                    ，故要谨慎从事，调整好自己的行为与心态，才能
                    化险为夷。<br>
                    2、行善积德，与人为善<br>
                    每个人要多做好事，善事、要多加烧香拜佛、行善
                    积德，破财消灾，行善所破之财是善财，不仅达到
                    破财的目的，也能为自己积累福报。<br>
                    3、利用风水助运<br>
                    命格发生大的不利往往是命中的五行之气不平衡发
                    生紊乱而致，故可以穿克制不利的五行气的颜色，
                    如命中金太旺的，可以穿红色。<br>
                    4、佩戴开运饰品<br>
                    除了家中摆放风水饰品，我们还可随身佩戴，比如
                    貔貅、金蟾、玉佛、等等都是不错的，流年不利肯
                    定是处处碰壁，严重还有血光灾祸，故带块玉求个
                    心安，也是不错的选择。以福压祸可以化解不利的
                    状况。</p>
                    <p style="font-weight:bold;padding-bottom:0;text-align:center;color: #E7010E;font-size: .32rem;">老师温馨寄语</p>
                    <p>环顾坊间的运程书，都喜以十二生肖作运势预测。
                    其实单以出生年份分出十二种类别来推算流年运势
                    ，虽有一定的参考价值，但却未免流于简单。因此
                    ，我便突破性地精算出八字流年运势预测，来补充
                    生肖运程之不足。
                    要是生肖流年运势好，但八字流年运势却不好，这
                    是一个坏消息；而如果生肖流年运势不好，然而八
                    字流年运势却大好，你便毋须担心，因为你的运势
                    会是偏向好方面的。</p>

                    
                </div>
            </div>
            <!-- <div class="content_box_">
                <h1 class="title_">您的真命分析</h1>
                <div class="content_text_box_">
                <p v-html="test_info.right_analysis"> </p>
                </div>
            </div> -->
            <!-- <div class="content_box_">
                <h1 class="title_">桃花好的年份</h1>
                <div class="content_text_box_">
                <p v-html="test_info.py_status_after.zheng"></p>
                </div>
            </div> -->
            <!-- <div class="content_box_">
                <h1 class="title_">容易遇到烂桃花的年份</h1>
                <div class="content_text_box_">
                    <p  v-html="test_info.py_status_after.fan"></p>
                </div>
            </div> -->
            <!-- <div class="content_box_">
                <h1 class="title_">感情容易生变的年份</h1>
                <div class="content_text_box_">
                    <p  v-html="test_info.py_over"></p>
                </div>
            </div> -->
            <!-- <div class="content_box_">
                <h1 class="title_">八字姻缘改运建议</h1>
                <div class="content_text_box_">
                    <p style="font-weight:bold;padding-bottom:0;">脱单技巧</p>
                    <p  v-html="test_info.yy_method.doggo"></p>
                    <p style="font-weight:bold;padding-bottom:0;">催旺爱情建议</p>
                    <p  v-html="test_info.yy_method.ml"></p>
                    <p style="color:#67386F;">（注：以上红袋子包裹干货若破碎霉变需及时更换，有效期为一年，次年除夕前更换为宜）</p>
                </div>
            </div> -->
            <div class="copy_img_buttom_box_" :data-clipboard-text="urls_" @click="copy"><img src="https://cdn.zhiyileiju.cn/WechatIMG1328.jpeg" alt=""></div>
            <div class="hint_info_text_" >复制链接后，可打开微信发送或保存链接,便于后期反复查阅</div>
            <div class="section_bottom_text_box_">
            您已看完报告所有内容，如果还有其他疑问
            </div>
            <div class="section_bottom_text_box_">
            可添加老师微信继续咨询哦~
            </div>

            <div class="teacher_consult_img_box_ animation_scaling" @click="consultingTeacherSkip('1')">
                <img src="https://cdn.zhiyileiju.cn/WechatIMG1312.png" alt="">
            </div>

            <!-- 热门推荐 -->
            <div class="hot_recommend_box_" id="hotRecommend">
                <h1 class="h1_title_">热门推荐</h1>
                 <div class="img_card_box_" @click="cardSkip('1')">
                    <img src="https://cdn.zhiyileiju.cn/WechatIMG1331.png" alt="">
                    <!-- <h2 class="card_text_"> 姻缘合婚分析  <span class="span_color_">326584人已经购买</span></h2> -->
                </div>
                <div class="img_card_box_" @click="cardSkip('2')">
                    <img src="https://cdn.zhiyileiju.cn/WechatIMG673.png" alt="">
                    <!-- <h2 class="card_text_"> 男女姓名配对分析  <span class="span_color_">231654人已经购买</span></h2> -->
                </div>
               
                <div class="img_card_box_" @click="cardSkip('3')">
                    <img src="https://cdn.zhiyileiju.cn/WechatIMG677.png" alt="">
                    <!-- <h2 class="card_text_"> 姓名测试打分  <span class="span_color_">236564人已经购买</span></h2> -->
                </div>
                <!-- <div class="img_card_box_ advisory_teacher_" @click="consultingTeacherSkip">
                    <img src="https://cdn.zhiyileiju.cn/WechatIMG631.png" alt="">
                </div> -->
            </div>
        
        </section>
        <footer v-if="fixedbtn">
            <!-- <div class="bottom_buttom_box">
                <span class="left_box span_" @click="anchorPoint">更多测试</span>
                <span class="right_box span_" @click="relationshipTitTesting">
                    <i class="icon_img_box_">
                        <img src="https://cdn.zhiyileiju.cn/WechatIMG628.pic" alt="">
                    </i>
                    恋爱契合度测试
                </span>
            </div> -->
        </footer> 
        <div class="fixation_box_">
            <div class="img_box_" @click="rightFixationSkip('1')"><img src="https://cdn.zhiyileiju.cn/WechatIMG1334.png" alt=""></div>
            <div class="img_box_" @click="rightFixationSkip('2')"><img src="https://cdn.zhiyileiju.cn/WechatIMG1333.png" alt=""></div>
            <div class="img_box_" @click="rightFixationSkip('3')"><img src="https://cdn.zhiyileiju.cn/WechatIMG1332.png" alt=""></div>
            <div class="img_box_" @click="rightFixationSkip('4')"><img src="https://cdn.zhiyileiju.cn/WechatIMG1335.png" alt=""></div>
        </div>
        <div class="complaint_box_"  @click="complaintButtom">
            <img src="https://cdn.zhiyileiju.cn/WechatIMG562.png" alt="">
        </div> 
        <!-- 返回顶部 -->
        <div class="return_top_box_" v-if="fixedbtn" @click="anchorPoint">
            <img src="https://cdn.zhiyileiju.cn/WechatIMG1345.pic" alt="">
        </div>
        <div class="manual_service_shade" @click="manualServicePopupClose" v-if="manual_service_shade_fal"></div>
        <div class="manual_service" v-if="manual_service_shade_fal"><img src="https://cdn.zhiyileiju.cn/WechatIMG1441.jpeg" alt=""></div>
    </div>
</template>
<script>
import Clipboard from 'clipboard'
import Paipan from "../../components/Paipan.vue";
import WuxingCate from "../../components/WuxingCate.vue";
import WuxingHl from "../../components/WxHanLg.vue";
import solarLunar from "../../components/datepick/solarlunar";
import { $orderNameDetail,$order } from "../../api/home_marriage_test"
import { $stat } from "../../api/stat"
export default {
    components: {
        Paipan,WuxingCate,WuxingHl
    },
    data () { 
        return{
            wechat_domain_name_skip:'https://jx2.gyqingqing.com',//微信跳转域名
            urls_:window.location.href,
            fixedbtn:false,
            pagination_:1,//当前页数
            manual_service_shade_fal:false,
             result_data:{},//结果页面数据
            info_data:{},//用户信息
            lunar_calendar_value:{
                cYear:"",
                yearCn_:"",
                cMonth:"",
                yearCn:"",
                cDay:"",
                monthCn:"",
                tiem_:"",
                dayCn:"",
                tiemCn_:"",
            },//农历时间
            test_info:{
                bz_ori:'',
                xg:'',
                bz_marriage:'',
                lucky_info_year:'',
                bz_marriage:'',
                py_status:{
                   zheng:'',
                    fan:''
                },
                bz_business:'',
                bz_money:'',
                lucky_info_month:[],
                jupiter:''


            },//测试信息
            orderinfo: {
                bDate: [2021],
                nongli: {},
                eightChara: {
                bazi: [
                    {
                    y: "辛丑",
                    },
                    {
                    m: "乙未",
                    },
                    {
                    d: "甲子",
                    },
                    {
                    h: "甲子",
                    },
                ],
                nayin: [
                    {
                    y: "壁上土",
                    },
                    {
                    m: "沙中金",
                    },
                    {
                    d: "海中金",
                    },
                    {
                    h: "海中金",
                    },
                ],
                zao: [
                    {
                    y: "辛丑",
                    },
                    {
                    m: "",
                    },
                    {
                    d: "",
                    },
                    {
                    h: "",
                    },
                ],
                },
                askTao: {
                ori_qk_score: {
                    金: 5,
                    木: 8,
                    水: 3,
                    火: 3,
                    土: 9,
                },
                asktao_result: {
                    喜用: {
                    水: 1.5,
                    金: 2.5,
                    },
                    忌用: {
                    木: 5.6,
                    火: 4.5,
                    土: 18,
                    },
                },
                qk_score_ratio: {
                    金: "6%",
                    木: "11%",
                    水: "25%",
                    火: "12%",
                    土: "46%",
                },
                ww_score_ratio: {
                    水: "38%",
                    金: "62%",
                },
                dw_score_ratio: {
                    木: "20%",
                    火: "16%",
                    土: "64%",
                },
                qk_score: {
                    金: 2.5,
                    木: 5.6,
                    水: 1.5,
                    火: 4.5,
                    土: 18,
                },
                },
            },
            enums: {
                木: "xys-mu",
                土: "xys-tu",
                火: "xys-huo",
                水: "xys-shui",
                金: "xys-jin",
            },
        }
    },
    watch: {
    '$route': function() {
        this.pagination_ = this.$route.meta.pagination
    }
    },
    created() {
        $stat(`v1_fleeting_time_result`);
        this.callback()
        sessionStorage.removeItem('user_id')
        window.addEventListener("scroll", this.handleScroll);
        this.pagination_ = this.$route.meta.pagination;
        this.orderNameDetailDataRequest();
        this.urls_ = 'https://jx2.gyqingqing.com' + window.location.pathname + window.location.search
        // console.log(window.location)
        $order( this.$route.params.id ).then ( (res ) => {
            this.orderinfo =  res.data.data
            // console.log('order',this.orderinfo)
        })
    },
    computed: {
		is_weixn() {
			var ua = navigator.userAgent.toLowerCase();
			if (ua.match(/MicroMessenger/i) == "micromessenger") {
				return true;
			} else {
				return false;
			}
		},
        WW() {
        return Object.keys(this.orderinfo.askTao.ww_score_ratio);
        },
        DW() {
        return Object.keys(this.orderinfo.askTao.dw_score_ratio);
        },
        WWrd() {
        return Object.keys(this.orderinfo.askTao.ww_score_ratio)
            .reduce(
            (a, b) =>
                this.orderinfo.askTao.qk_score[a] +
                this.orderinfo.askTao.qk_score[b]
            )
            .toFixed(1);
        },
        DWrd() {
        let a = 0;
        Object.keys(this.orderinfo.askTao.dw_score_ratio).forEach((key) => {
            a = a + this.orderinfo.askTao.qk_score[key];
        });
        return a.toFixed(1);
        },
    },
    methods:{
        callback(){
            $order(this.$route.params.id).then( ( res ) => {
                if (res.status === 200 ) {
                    this.info = res.data.data;
                        console.log(this.info)
                    if(!this.info.discount_amount){
                        let fromk = this.$route.query.from;

                        let needCallback = ['ya1','yb1','yb2','yc1','yd1','yb3','yb4','xw1','yc2','yc3','yh1','yh2','yb5'];

                        let callbackId = {
                            ya1:391443, 
                            yb1:391469, 
                            yb2:391472, 
                            yb3:391683, 
                            yb4:391680, 
                            yc1:391534, 
                            yc2:392310, 
                            yc3:392742, 
                            yd1:391664, 
                            yh1:392848, 
                            yh2:392849, 
                            xw1:392065,  
                            yb5:393036, 
                            
                        }
                        if(needCallback.indexOf(fromk) > -1){
                            _ks_trace.push({event: 'form', convertId: callbackId[fromk], cb: function(){ }})
                            // console.log('100%',{event: 'form', convertId: callbackId[fromk], cb: function(){ }})
                        };

                        let needCallback_95 = [ //95%回传快手
                            // 'xf5'
                            ];
                        let callbackId_95 ={
                            // xt2:369373,
                            // xf5:371528,
                            // xq1:373528, 
                        }

                        if(needCallback_95.indexOf(fromk) > -1 && Math.random() < 0.95 ){
                            // console.log('95%')
                            _ks_trace.push({event: 'form', convertId: callbackId_95[fromk], cb: function(){ }})
                        }

                        let needCallback_15 = [ //15%回传快手
                            // 'xp1'
                            
                            ];
                        let callbackId_15 ={
                            // xp1:368032, 
                        }

                        if(needCallback_15.indexOf(fromk) > -1 && Math.random() < 0.15 ){
                            // console.log('95%')
                            _ks_trace.push({event: 'form', convertId: callbackId_15[fromk], cb: function(){ }})
                        }
                    }else{
                        // let fromk = this.$route.query.from;
                        // let needCallback = ['xxxx','xxx','xxx'];
                        // let callbackId ={
                        //     xxxx:369373,
                        //     xxx:371528,
                        //     xxx:373528, 
                        // }

                        // if(needCallback.indexOf(fromk) > -1 && Math.random() < 0.95 ){
                        //     _ks_trace.push({event: 'form', convertId: callbackId[fromk], cb: function(){ }})
                        // }
                    }
                }
            })
        },
        handleScroll() {//y页面向下拉300时显示按钮
			var scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			if (scrollTop > 300) {
				this.fixedbtn = true;
			} else {
				this.fixedbtn = false;
			}
		},
        
        copy() {//复制按钮
            // console.log(window.location.href)
            var clipboard = new Clipboard('.copy_img_buttom_box_')
            clipboard.on('success', e => {
                this.$toast.success("复制成功");
                // console.log('复制成功')
                //  释放内存
                // clipboard.destory()
            })
                clipboard.on('error', e =>{
                // 不支持复制
                console.log('该浏览器不支持复制')
                // 释放内存
                // clipboard.destory()
            })
        },
        orderNameDetailDataRequest() {//用户信息接口
            let dataRequest_data_ = {
                order_id: this.$route.params.id,
                name_id: 0,
                // mnew:1
            }
            $orderNameDetail(dataRequest_data_).then( ( res ) => {//详情数据
                if ( res.status === 200) {
                    this.result_data = res.data.data
                    this.info_data = res.data.data.base
                    this.test_info = this.result_data.luckyData
                    let date_arr = res.data.data.base.birth_date.substr(0,10).split("-")
                    let time_arr = res.data.data.base.birth_date.substr(11,8).split(":")
                    date_arr.push(...time_arr)
                    this.lunar_calendar_value = solarLunar.solar2lunar(//获取农历
                        date_arr[0],
                        date_arr[1],
                        date_arr[2],
                    );
                    this.lunar_calendar_value.tiem_ = time_arr[0]//时
                    this.lunar_calendar_value.tiemCn_ = (res.data.data.base.moon_time.split("#"))[1]//农历时
                    this.lunar_calendar_value.yearCn_ = (res.data.data.base.moon_time.split("#"))[0]//农历时
                }
                
            })
        },


        nameMatchingSkip() { //姓名配对按钮
            $stat("v1_fleeting_time_fortune_result_top_buttom")
            if ( this.is_weixn === true ) {
                window.location.href=`${this.wechat_domain_name_skip}/wechatmerry?fromk=lndbgd`
            }else{
                window.location.href=`/home_marriage_test?from=lndbgd`
            }
        },
        calculationOfEmotionalMarriageBannerSkip() {//感情合婚测算 banner
            window.location.href="https://jx2.gyqingqing.com/wechatmatch?fromk=zbpd"
        },
        paginationcallback(params){//翻页
            if( params === 'relief' ) { //上一页
                this.pagination_--
            }else if( params === 'gagarin' ) { //下一页
                 this.pagination_++
            }
            
            if ( this.pagination_ < 1 ) {
                this.pagination_ = 4
            }else if ( this.pagination_ > 4) {
                this.pagination_ = 1
            }
            switch (this.pagination_) {
                case 1:
                    this.$router.push('/home_marriage_test/payment/result/' + this.$route.params.id + '/' + this.$route.params.type + '/information')
                    break;
                case 2:
                    this.$router.push('/home_marriage_test/payment/result/' + this.$route.params.id + '/' + this.$route.params.type + '/analysis')
                    break;
                case 3:
                    this.$router.push('/home_marriage_test/payment/result/' + this.$route.params.id + '/' + this.$route.params.type + '/suggest')
                    break;
                case 4:
                    this.$router.push('/home_marriage_test/payment/result/' + this.$route.params.id + '/' + this.$route.params.type + '/increase')
                    break;
            
                default:
                    break;
            }
        },
        //热门推荐
        // https://jx2.gyqingqing.com/wechatmatch?fromk=dbpd
        cardSkip(type) {//男女姓名配对分析
            $stat(`v1_fleeting_time_fortune_result_card_${type}`)
            switch (type) {
                case '1':
                    if ( this.is_weixn === true ) {
                        window.location.href=`${this.wechat_domain_name_skip}/wechatmerry?fromk=lnrt1`
                    }else{
                        window.location.href=`/home_marriage_test?from=lnrt1`
                    }
                    break;
                case '2':
                    if ( this.is_weixn === true ) {
                        window.location.href=`${this.wechat_domain_name_skip}/wechatmatch?fromk=lnrt2`
                    }else{
                        window.location.href=`/name_matching?from=lnrt2`
                    }
                    break;
                case '3':
                    if ( this.is_weixn === true ) {
                        window.location.href=`${this.wechat_domain_name_skip}/wechattestname?fromk=lnrt3`
                    }else{
                        window.location.href=`https://www.zhiyileiju.cn/aitestname?from=lnrt3`
                    }
                    break;
            
                default:
                    break;
            }
        },
        consultingTeacherSkip(id) {// 咨询老师按钮
            $stat(`v1_fleeting_time_fortune_result_center_consult_buttom_${id}`)
             this.manual_service_shade_fal = true
            // this.$router.push('/mini_program_page/result?qr=1')
        },
        anchorPoint () {//锚点
            $stat(`v1_fleeting_time_result_top_left_buttom`)
            document.querySelector("#agentTitle").scrollIntoView({behavior: "smooth", block: "start" });
        },
        relationshipTitTesting () {//恋爱契合度测试
            $stat(`v1_fleeting_time_result_top_right_buttom`)
            window.location.href="https://jx2.gyqingqing.com/wechatmatch?fromk=dgpd"
        },
        rightFixationSkip(type) {//右边屏幕固定按钮
            $stat(`v1_fleeting_time_fortune_result_right_fixation_buttom_${type}`)
            switch (type) {
                case '1':
                    if ( this.is_weixn === true ) {
                        window.location.href=`${this.wechat_domain_name_skip}/wechatmerry?fromk=lnxf1`
                    }else{
                        window.location.href=`/home_marriage_test?from=lnxf1`
                    }
                    break;
                case '2':
                    this.consultingTeacherSkip()
                    break;
                case '3':
                    if ( this.is_weixn === true ) {
                        window.location.href=`${this.wechat_domain_name_skip}/wechatmatch?fromk=lnxf3`
                    }else{
                        window.location.href=`/name_matching?from=lnxf3`
                    }
                    break;
                case '4':
                    if ( this.is_weixn === true ) {
                        window.location.href=`${this.wechat_domain_name_skip}/wechattestname?fromk=lnxf4`
                    }else{
                        window.location.href=`https://www.zhiyileiju.cn/aitestname?from=lnxf4`
                    }
                    break;
            
                default:
                    break;
            }
        },
        complaintButtom () {//投诉
            $stat("v1_fleeting_time_fortune_result_complaint")
            window.location.href = 'https://www.wjx.top/vj/hd0JNnc.aspx'
        },
        manualServicePopupClose () {//关闭弹窗
            this.manual_service_shade_fal = false
        },
    }
}
</script>
 <style lang="scss" scoped>
    .Result_{
        header{
            position: fixed;
            top: 0;
            width: 7.5rem;
            z-index: 2;
            .label_box{
                width: 100%;
                height:.8rem;
                .label_text{
                    display: inline-block;
                    width: 50%;
                    height:.8rem;
                    line-height: .8rem;
                    font-size: .32rem;
                    font-weight: bold;
                    text-align: center;
                }
                .label_text_left{
                    float: left;
                    color: #FFFFFF;
                    background: #E7010E;
                }
                .label_text_right{
                    position: relative;
                    float: right;
                    box-sizing: border-box;
                    border: 1px solid #C91C18;
                    color: #C91C18;
                    background: #FFFFFF;
                    .hot_img_box{
                        position: absolute;
                        width: .46rem;
                        right: .74rem;
                        top: -.14rem;
                    }
                    .fire_img_box{
                        position: absolute;
                        width: .24rem;
                        left: .8rem;
                        top: 0;

                    }
                }
            }
            .banner_img_box_{
                width: 7.5rem;
            }
        }
        section{
            padding-top: .8rem;
            padding-bottom: 1.11rem;
            background: #fffae8;
           
            .info_color_box_{
                position: relative;
                width: 5.74rem;
                margin-left: 1.16rem;
                font-size: .28rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #E7010E;
                margin-bottom: 0.3rem;
                .icon_img_{
                    position: absolute;
                    width: .32rem;
                    top: 0.05rem;
                    left: -0.5rem;
                }

            }
            .info_text_box_{
                position: relative;
                width: 5.74rem;
                margin-left: 1.16rem;
                font-size: .28rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #323232;
                margin-bottom: .5rem;
                line-height: .6rem;
            }

            .Official_Accounts_buttom_{
                position: relative;
                width: 6.52rem;
                height: 1.06rem;
                background: #EC703A;
                border-radius: .53rem;
                font-size: .42rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                text-align: center;
                line-height: 1.06rem;
                margin: auto;
                margin-top: -.05rem;
                .Official_Accounts_icon_{
                    position: absolute;
                    width: .63rem;
                }
            }

            
            .content_box_{
            width: 7.18rem;
            border: .03rem solid #DC5744;
            opacity: 0.7;
            border-radius: .30rem;
            padding-bottom: .6rem;
            margin: 0 auto;
            margin-bottom: .48rem;
            background: #fff;
            .title_{
                width: 100%;
                height: .78rem;
                line-height: .78rem;
                font-size: .36rem;
                color: #C91C18;
                text-align: center;
                font-weight: bold;
                background: #fae7e5;
                margin-top: .25rem;
            }
            .content_name_text_box_{ //您的信息
                // width: 100%;
                padding-left: .69rem;
                p{
                    font-size: .28rem;
                    color: #525252;
                    margin-top: .27rem;
                }
                .name_{
                    margin-top: .35rem;
                }
                .span_style__1{
                    .text__{
                        letter-spacing:2em
                    }
                }
            }
            .content_text_box_{
                padding: 0 .35rem;
                p{
                    font-size: .28rem;
                    color: #525252;
                    margin-top: .35rem;
                }
                //八字盘
            }
            .horoscope_box{
                width: 7.08rem;
                margin: 0 auto;
                border-radius: .3rem;
                text-align: center;
                background: #fff;
                // margin-bottom: 1rem;
                padding-top: .03rem;
                // padding-bottom: .5rem;
                margin-top: .36rem;
                .eight_layout{
                    text-align: left;
                    .text{
                        text-align: center;
                        font-size: .35rem;
                        margin: .3rem 0;
                    }
                    .center{
                        text-align: center;
                    }
                    .tit1 {
                        font-size: .3rem;
                        font-weight: bold;
                        color: #020202;
                        line-height: 1.8em;
                    }
                    .ml-15 {
                        margin-left: .25rem;
                    }

                    .txt {
                        font-size: .3rem;
                        font-weight: 400;
                        color: #020202;
                        line-height: 2em;
                        margin: .06rem .25rem;
                        margin-left: .25rem;
                    }
                    .wchl{
                        margin-left: .4rem;
                    }
                    .xjwx{
                        em {
                            border-radius: 50%;
                            width: .60rem;
                            height: .60rem;
                            color: white;
                            font-size: .4rem;
                            line-height: .6rem;
                            display: inline-block;
                            text-align: center;
                            margin-right: 3px;
                            font-style: normal;
                        }
                    } 
                }
            }
            
            
            }
            .copy_img_buttom_box_{
                width: 4.29rem;
                margin: auto;
            }
            .hint_info_text_{
                width: 7.5rem;
                height: .78rem;
                text-align: center;
                font-size: .24rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #E7010E;
                line-height: .78rem;
                background: rgba(220, 87, 68, .2);
            }
            .section_bottom_text_box_{
                text-align: center;
                font-size: .32rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #525252;
                margin-top: .28rem;
                // line-height: 41px;
            }
            .teacher_consult_img_box_{
                width: 6.5rem;
                margin: auto;
                margin-top: .28rem;
                margin-bottom: .3rem;
            }

            .hot_recommend_box_{ //热门推荐
                // margin-top:1.3rem;
                .h1_title_{
                    width: 7.5rem;
                    height: .78rem;
                    font-size: .36rem;
                    font-weight: bold;
                    line-height: .78rem;
                    text-align: center;
                    color: #C91C18;
                    background: rgba(220,87,68,.2);
                    margin-bottom: .48rem;
                }
                .img_card_box_{
                    width: 7.18rem;
                    margin: 0 auto;
                    margin-bottom: .2rem;
                    .card_text_{
                        font-size: .24rem;
                        font-weight: normal;
                        color: #C91C18;
                        .span_color_{
                            color: #525252;
                        }
                    }
                }
                .advisory_teacher_{
                    margin-top: .4rem;
                    
                }
            }
        }
        footer{
            overflow: hidden;
            position: fixed;
            bottom: 0;
            width: 7.5rem;
            z-index: 2;
            .bottom_buttom_box{
                width: 7.5rem;
                height: 1.11rem;
                .span_{
                    position: relative;
                    height: 1.11rem;
                    line-height: 1.11rem;
                    font-size: .36rem;
                    text-align: center;
                }
                .left_box{
                    float: left;
                    width: 2.19rem;
                    color: #C91C18;
                    box-sizing: border-box;
                    border: .01rem solid #C91C18;
                    background: #fff;
                }
                .right_box{
                    float: right;
                    width: 5.31rem;
                    color: #fff;
                    background: #C91C18;
                    .icon_img_box_{
                        display: inline-block;
                        width: .34rem;
                    }
                }
            }
        }

        .fixation_box_{
            position: fixed;
            top: 3rem;
            right: 0;
            width: .87rem;
            .img_box_{
                width: .87rem;
                margin-top: -0.15rem;
            }
        }
        .complaint_box_{
            position: fixed;
            top: 8.1rem;
            right: 0;
            width: .48rem;
        }
        .return_top_box_{
            position: fixed;
            top: 10.1rem;
            right: 0;
            width: .48rem;
        }

         .manual_service{
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 5.14rem;
            z-index: 11;
        }
        //遮罩
        .manual_service_shade{
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background:  rgba(0,0,0,.5);
            z-index: 10;
        }
    }
 </style>