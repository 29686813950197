<template>
  <div class="new-xys-samekind">
    <span
      >八字同类：
      <!-- <em v-for="(value, key, index) in data.ww_score_ratio" :key="value" :class="emums[key]">{{ -->
      <em v-for="(value, key, index) in data.ww_score_ratio" :key="index" :class="emums[key]">{{
        key
      }}</em>
    </span>
    <span
      >八字异类：
      <em v-for="(value, key, index) in data.dw_score_ratio" :key="index" :class="emums[key]">{{
        key
      }}</em>

    </span>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  computed: {
    cdata() {
      let wwtemp = [];

      return wwtemp;
    },
  },
  data() {
    return {
      emums: {
        水: "xys-shui",
        木: "xys-mu",
        土: "xys-tu",
        金: "xys-jin",
        火: "xys-huo",
      },
    };

    // console.log(this.data);
    // this.data.ww_score_ratio.keys().forEach(key => {
    //     console.log(key)
    //     wwtemp.push({
    //       key,
    //       class:this.emums[key]
    //     })
    //   });

    return {};
  },
};
</script>

<style lang="scss" scope>
.new-xys-samekind {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  em {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: white;
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
    text-align: center;
    margin-right: 3px;
    font-style: normal;
  }
}

.new-xys-samekind span em:last-child {
  margin-right: 0;
}

.xys-jin {
  background: #f6bd41;
}

.xys-mu {
  background: #98c4a0;
}

.xys-shui {
  background: #7fc8f9;
}

.xys-huo {
  background: #eb7653;
}

.xys-tu {
  background: #d1c0a6;
}
</style>
